import React from "react";
import { Box } from "@mui/material";
import Header from "../../components/Header";

const Home = () => {
  return (
    <Box width={"90%"} height={"90%"} m="1.5rem 2.5rem">
      <Header
        title={"Patient Entry Form"}
        subtitle={"Fill in the patient entry form, with the patient's details"}
      />
      <Box width={"100%"} height={"100%"} mt={"20px"}>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSeVSwSGC4pBklNt6V8hA7v9xk6TewhJfSvn8ymIJCuYNHYqVQ/viewform?embedded=true"
          width="80%"
          height="100%"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading...
        </iframe>
      </Box>
    </Box>
  );
};

export default Home;
