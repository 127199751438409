import React from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
  IconButton,
} from "@mui/material";
import {
  HomeOutlined,
  ChevronRightOutlined,
  Brightness4,
  Brightness7,
  ChevronLeft,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import { useDispatch } from "react-redux";
import { setMode } from "../redux/global";
import { Icon } from "@iconify/react";

const Navbar = ({
  isNavbarOpen,
  setIsNavbarOpen,
  isNonMobile,
  drawerWidth,
}) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  const navItems = [
    {
      text: "Entry Form",
      route: "",
      icon: <Icon icon="openmoji:patient-clipboard" width={"32"} />,
      necessary: true,
      external: false,
    },
    {
      text: "Results",
      route: "results",
      icon: <Icon icon="uil:chart-line" width={"24"} />,
      necessary: true,
      external: false,
    },
  ];

  return (
    <Box component="nav">
      {isNavbarOpen && (
        <Drawer
          open={isNavbarOpen}
          onClose={() => setIsNavbarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSizing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 1rem 1rem">
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Typography variant="h4" fontWeight="bold">
                    HeisenbergK - MI
                  </Typography>
                </Box>
                {!isNonMobile && (
                  <IconButton
                    onClick={() => setIsNavbarOpen(!isNavbarOpen)}
                    sx={{ ml: 1 }}
                    color="inherit"
                  >
                    <ChevronLeft />
                  </IconButton>
                )}
                <IconButton
                  sx={{ ml: 1 }}
                  onClick={() => dispatch(setMode())}
                  color="inherit"
                >
                  {theme.palette.mode === "dark" ? (
                    <Brightness7 />
                  ) : (
                    <Brightness4 />
                  )}
                </IconButton>
              </FlexBetween>
            </Box>
            <List>
              {navItems.map(({ text, route, icon, necessary, external }) => {
                if (necessary) {
                  if (!icon) {
                    return (
                      <Typography key={text} sx={{ m: "2.5rem 0 1rem 3rem" }}>
                        {text}
                      </Typography>
                    );
                  }
                  // const lcText = text.toLowerCase();
                  const lcText = route;
                  return (
                    <ListItem key={text} disablePadding>
                      <ListItemButton
                        onClick={() => {
                          if (!external) {
                            navigate(`/${lcText}`);
                            setActive(lcText);
                          } else {
                            window.open(lcText, "_blank", "noreferrer");
                          }
                        }}
                        sx={{
                          backgroundColor:
                            active === lcText
                              ? theme.palette.secondary[300]
                              : "transparent",
                          color:
                            active === lcText
                              ? theme.palette.primary[600]
                              : theme.palette.secondary[100],
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ml: "2rem",
                            color:
                              active === lcText
                                ? theme.palette.primary[600]
                                : theme.palette.secondary[200],
                          }}
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                        {active === lcText && (
                          <ChevronRightOutlined sx={{ ml: "auto" }} />
                        )}
                      </ListItemButton>
                    </ListItem>
                  );
                } else {
                  return null;
                }
              })}
            </List>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Navbar;
