import React from "react";
import { Box, Typography } from "@mui/material";
import Header from "../../components/Header";

const Results = () => {
  const resultsLive = true;
  return (
    <Box width={"90%"} height={"90%"} m="1.5rem 2.5rem">
      <Header
        title={"Study Results"}
        subtitle={"Live statistics and results of the study"}
      />
      <Box width={"95%"} height={"100%"} mt={"20px"}>
        {resultsLive ? (
          <iframe
            width="80%"
            height="100%"
            src="https://lookerstudio.google.com/embed/reporting/02cedf4d-8de1-4369-80b0-6900c72f6991/page/eDxwD"
            allowfullscreen
            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
          ></iframe>
        ) : (
          <Typography variant={"h1"}>Stay Tuned...</Typography>
        )}
      </Box>
    </Box>
  );
};

export default Results;
