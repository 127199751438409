import { configureStore } from '@reduxjs/toolkit'
import messageReducer from "./redux/message";
import globalReducer from "./redux/global";

const reducer = {
    message: messageReducer,
    global: globalReducer,
}

const store = configureStore({
    reducer: reducer,
    middleware: (getDefault) => getDefault(),
    devTools: true,
})

export default store;